import React from "react";

const Services = () => {
  return (
    <section id="services" className="w-screen px-8 py-20 z-10">
      <div className="container mx-auto">
        <h2 className="text-3xl text-brand-grayish-blue font-bold tracking-widest text-center uppercase">
          Ծառայություններ
        </h2>

        <div className="w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-8 mt-16 relative">
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Պատուհանների մաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Խոհանոցի մաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Սառնարանի մաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Գազօջախի մաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Փոշեկուլում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Գոլորշիյով մաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Քիմմաքրում
            </p>
          </div>
          <div className="flex items-center justify-center border-[1px] py-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Արդուկում
            </p>
          </div>
        </div>

        <div className="mt-10 text-center">
          <div className="inline-flex items-center justify-center border-[1px] py-4 px-4 bg-white text-gray-800 rounded-lg z-10">
            <p className="text-base sm:text-lg px-1">
              Նաև այլ ծառայություններ
              <br />
              ...
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
