import React from "react";

const Pricing = () => {
  return (
    <div id="packages" className="w-screen pt-[80px] px-8 pb-12 flex justify-center items-center bg-white">
      <div className="container mx-auto relative z-20">
        <h2 className="text-3xl text-brand-grayish-blue font-bold tracking-widest text-center uppercase">
          Փաթեթներ
        </h2>

        <div className="flex flex-wrap gap-12 lg:gap-8 mx-auto pt-16 pb-16 px-4 sm:px-6 lg:px-8">
          {/* <div className="hidden lg:flex-1 flex flex-col relative border border-slate-200 p-8 rounded-lg bg-white">
            <h3 className="text-xl font-semibold leading-5">Մինի</h3>

            <p className="absolute top-0 -translate-y-1/2 bg-cyan-500 text-white px-3 py-0.5 text-sm text-white text-semibold tracking-wide rounded-full shadow-md">Էքսպերիմենտալ</p>

            <div className="mt-4 bg-slate-50 p-6 rounded-lg -mx-6">
              <div className="flex flex-col justify-center text-sm font-semibold text-slate-400">
                <span>3 ժամը</span>
                <div>
                  <span className="text-4xl text-slate-900">6,500</span>
                  <span className="ml-1.5">դր․</span>
                </div>
              </div>
            </div>

            <ul className="flex-1 mt-6 space-y-4">
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Մեկ մաքրուհի</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սենյակներ</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Խոհանոց</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սանհանգույց</span>
              </li>
            </ul>

            <p className="mt-6 text-slate-800 leading-6">
              Սենյակների և խոհանոցի Սենյակների մաքրում Ձեր տրամադրած նյութերով և պարագաներով, նաև փոշեկուլում և սանհանգույցի մաքրում։ 
            </p>
          </div> */}

          <div className="flex-1 flex flex-col relative border border-slate-200 p-8 rounded-lg bg-white">
            <h3 className="text-xl font-semibold leading-5">Միկրո</h3>

            <div className="mt-4 bg-slate-50 p-6 rounded-lg -mx-6">
              <div className="flex flex-col justify-center text-sm font-semibold text-slate-400">
                <span>3 ժամը</span>
                <div>
                  <span className="text-4xl text-slate-900">6,500</span>
                  <span className="ml-1.5">դր․</span>
                </div>
              </div>
            </div>

            <ul className="flex-1 mt-6 space-y-4">
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սենյակներ</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Խոհանոց</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սանհանգույց</span>
              </li>
            </ul>

            <p className="mt-6 text-slate-800 leading-6">
              Ներառում է խոհանոցի, սանհանգույցի և սենյակների ընթացիկ մաքրումը Ձեր կողմից տրամադրած նյութերով և պարագաներով
            </p>
            {/* <a href="#" className="block text-cyan-700 bg-cyan-50 hover:bg-cyan-100 mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Select Plan</a> */}
          </div>

          <div className="flex-1 flex flex-col relative border border-slate-200 p-8 rounded-lg bg-white">
            <h3 className="text-xl font-semibold leading-5">Էկոնոմ</h3>

            <div className="mt-4 bg-slate-50 p-6 rounded-lg -mx-6">
              <div className="flex flex-col justify-center text-sm font-semibold text-slate-400">
                <span>5/7 ժամը սկսած</span>
                <div>
                  <span className="text-4xl text-slate-900">12,000/15,000</span>
                  <span className="ml-1.5">դր․</span>
                </div>
              </div>
            </div>

            <ul className="flex-1 mt-6 space-y-4">
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սենյակներ</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Խոհանոց</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Սանհանգույց</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6 opacity-60">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Պատուհաններ</span>
              </li>
            </ul>

            <p className="mt-6 text-slate-800 leading-6">
              Ներառում է խոհանոցի, սանհանգույցի և սենյակների ընթացիկ մաքրումը, նաև սահմանված չափով պատուհանների մաքրում
            </p>
            {/* <a href="#" className="block text-cyan-700 bg-cyan-50 hover:bg-cyan-100 mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Select Plan</a> */}
          </div>

          <div className="flex-1 flex flex-col relative border border-slate-200 p-8 rounded-lg bg-white">
            <h3 className="text-xl font-semibold leading-5">Մուլտի</h3>

            <div className="mt-4 bg-slate-50 p-6 rounded-lg -mx-6">
              <div className="flex flex-col justify-center text-sm font-semibold text-slate-400">
                <span>6 ժամը սկսած</span>
                <div>
                  <span className="text-4xl text-slate-900">26,000</span>
                  <span className="ml-1.5">դր․</span>
                </div>
              </div>
            </div>

            <ul className="flex-1 mt-6 space-y-4">
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">2 մաքրուհի</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Խորը մաքրում</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Արագություն +</span>
              </li>
              <li className="flex text-sm text-slate-800 leading-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-indigo-700"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                <span className="ml-3">Որակ +</span>
              </li>
            </ul>

            {/* <p className="mt-4 text-slate-800 leading-6">Our most popular plan with lots of features</p> */}
            {/* <a href="#" className="block text-white bg-cyan-500 hover:bg-cyan-600 mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Select Plan</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
