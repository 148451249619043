import React from "react";

const scrollToSection = (sectionName, event) => {
  event.preventDefault()
  const section = document.getElementById(sectionName)
  if (!section) {
    return
  }

  window.scrollTo({ behavior: 'smooth', top: section.offsetTop - 50 })
}

function App() {
  const Header = () => {
    return (
      <div className="w-screen h-[100px] px-8 flex align items-center fixed z-40 border-b-2 border-primary-complement backdrop-blur-sm bg-white/90 ">
        <div className="container flex mx-auto">
          <a className="flex items-center" href="/">
            <img src="/img/logo-horizontal.svg" alt="Maqur Service logo" width="222" height="60"/>
          </a>

          <div className="flex justify-between items-center ml-auto">
            {/* <button type="button" className="relative group px-5">
              Ծառայություններ

              <ul className="group-hover:flex absolute z-10 hidden top-0 bg-white rounded-xl drop-shadow-md section-container">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </button> */}

            <a onClick={(event) => scrollToSection('services', event)} href="#" className="m-2 p-2 font-bold">
              Ծառայություններ
            </a>
            <a onClick={(event) => scrollToSection('packages', event)} href="#" className="m-2 p-2 font-bold">
              Փաթեթներ
            </a>
            {/* <a href="#" className="m-2 p-2 font-bold">
              Բոնուսներ
            </a> */}
            {/* <a href="#" className="m-2 p-2 font-bold">
              Հատուկ առաջարկ
            </a> */}

            <button onClick={(event) => scrollToSection('booking', event)} type="button" className="ml-5 py-4 px-5 bg-primary text-white uppercase tracking-widest font-bold rounded-full">
              Ամրագրել
            </button>
          </div>
        </div>
      </div>
    );
  };

  return <Header />;
}

export default App;
