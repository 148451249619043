import React from "react";

function App() {
  const Hero = () => {
    return (
      <div className="w-screen pt-[80px] px-8 flex justify-center items-center">
        <div className="mt-6 w-full h-full relative overflow-hidden rounded-3xl">
          <img src="/img/hero-5.jpg" className="absolute w-full h-full z-10 top-0 bottom-0 left-0 right-0 hero-image" draggable="false" />

          <div className="absolute z-10 w-full h-full top-0 bottom-0 left-0 right-0 bg-white/80"></div>

          <div className="h-[280px]"></div>

          <div className="container mx-auto relative z-20">
            <div className="flex">
              <div className="w-full p-6 mb-8 backdrop-blur-sm md:w-1/2 rounded-3xl">
                <h1 className="text-4xl font-bold leading-relaxed tracking-widest">Մաքրման Ծառայություններ <br/> Երևանում</h1>

                <p className="text-xl mt-4">- Բնակարանների, տների, գրասենյակների մաքրում</p>
                <p className="text-xl mt-4">- Հետշինարարական մաքրում</p>
                <p className="text-xl mt-4">- Քիմմաքրում</p>
                <p className="text-xl mt-4">- Գոլորշիյով մաքրում</p>

                <div className="h-[200px]"></div>
              </div>
              {/* <div className="w-full p-6 mb-8 backdrop-blur-sm md:w-1/2 rounded-3xl">
                <a href="tel:+37499179080" className="py-4 px-5 bg-primary text-white uppercase tracking-widest font-bold rounded-full">
                  099 17 90 80
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <Hero />;
}

export default App;
